@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&family=Roboto:wght@400;700&display=swap');

body{
    font-family: 'Poppins', 'Roboto', sans-serif;
    /* background-color: #383739; */
}
.containerfluids{
    /* border: 2px solid green; */
    height: 100vh;
}
.main_parent_div{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-style: none solid none solid;
    /* border-width: 0px 12px 0px 0px; */
    /* border: 25px solid black; */
    /* border-color: #111111; */
    /* border-color: white; */
    /* background: #111111; */
    /* background-color: white; */
    /* color: black; */
    /* background: #111111; */
    /* background: red; */
    /* border: 2px solid red; */

}
/* .main_parent_div.dark-theme{
    background-color: #111111;
    color: #747d8a;
} */
.dark-theme{
    border-color: black;
    background-color: #111111;
    color: #989a9c;
}
.light-theme{
    background-color: white;
    color: #45494e;
    border-color: white;
}
.parent-top.light-theme{
    background: whitesmoke;
    color: #747d8a;
}

.parent-top{
    width: 100%;
    /* border-style: solid solid solid solid; */
    /* border-width: 5px; */
    /* margin-top: 50px; */
    /* position: relative; */
    border-radius: 17px;
    background: #313131;
    /* background: red; */
    /* background-color: white; */
    /* color: black; */
    /* border: 5px solid rgb(15, 14, 14); */
    /* border: 5px solid white; */
}

.img-outerbot{
    position: fixed;
    right: 0;
    width: 11.5%;
    bottom: 0%;
}
.capyrights_para{
    margin: 0%;
}
.copyrights{
    /* color: white; */
    /* margin: 72px auto 0 auto; */
    /* border: 2px solid red; */
    border-radius: 7px;
    /* background: #111111; */
    /* background-color: whitesmoke; */
    width: 100%;
    /* color: #747d8a; */
    /* margin-top: 1rem; */
    /* box-shadow: 2px 4px 25px 5px rgb(207, 210, 210); */
    font-size: small;
    display: flex;
    height: 65px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* text-align: center; */
}

::-webkit-scrollbar {
    width: 5px;
    border-radius: 30px;
}

::-webkit-scrollbar-track {
    /* background-color: transparent; */
    border-radius: 10px;
    background-color: #747d8a;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    /* background-color: transparent; */
    background-color: #383739;
}
@media (max-width: 767px) {
    .main_parent_div{
        overflow-y: scroll;
    }
    .copyrights{
        margin: 2rem 0rem 3rem 0rem;
    }
    .capyrights_para{
        text-align: center;
    }
}
