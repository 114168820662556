*{
    margin:0;
    padding:0;
    text-decoration: none;
}
.containers{
    display:flex;
    width: 100%;

    /* border: 2px solid red; */
}
.capyrights_para{
    margin: 0%;
}
.recent{
    color: #798699;
    font-size: 12px;
    margin-bottom: 0px;
    padding: 14px 14px 14px 20px;
    /* color: #263238; */
    font-weight: 500;
}
.setting_con{
    animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
.top_heder_part{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    /* overflow-y: scroll; */
    /* border: 2px solid red; */
    justify-content: space-between;
    width: 100%;
}
.create_chat{
    width: 100%;
    /* border: 2px solid yellowgreen; */

    margin:15% 0% 20% 0%;
    display: flex;
    /* align-items: center; */
    justify-content: center;

}
.create_chat_btn{
    /* background: #323840; */
    color: #afadad;
    /* display: flex; */
    /* margin: auto; */
    border: none;
    outline: none;
    border-radius: 7px;
    width: 100%;
    height: 40px;
    font-size: 14px;

}
.create_chat_btn.dark-theme{
    background: #323840;
}
.create_chat_btn.light-theme{
    background: whitesmoke;
}
.header{
    padding: 0% 2% 0% 2%;
    width: 100%;
    height: 55px ;
    background-color: #111111;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;

}
main{
    width: 100%;
    /* padding: 20px; */
    /* border: 2px solid green; */
}
.sidebars{
    background: #111111;
    color: #fff;
    height: 100vh;
    overflow-y: scroll;
    width: 250px;
    padding: 0% 1.3% 0% 1.8%;
    transition: all 0.5s;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    /* border: 5px solid darkgreen; */
}
.sidebars::-webkit-scrollbar {
    width: 2px;
    border-radius: 30px;
    /* border: 1px solid rgba(14, 13, 13, 0.125); */
    /* background-color: #313131; */
}
.sidebar_div{
    width: 100%;
    padding: 0% 10% 0% 2%;
    height: auto;
}

.sidebars::-webkit-scrollbar-track {
    background-color: transparent;
}

.sidebars::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.room_navigator{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0% 0% 0% 4%;
    width: 100%;
    margin-bottom: 0.2rem;
}
.top_section{
    display: flex;
    align-items:center;
    justify-content: space-between;
    padding:20px 15px;
}
.image_div{
    /* border: 2px solid yellow; */
    /* width: 200px; */
    /* height: 120px; */
    filter: invert(0);
    margin-right: 1rem;
}
.logo{
    font-size: 10px;
    /* width */
    width: 170px ;
    height: 50px;
    /* border: 2px solid green; */
}
.logos{
    font-size: 10px;
}
.bars{
    display: flex;
    font-size: 15px;
    margin-left: 50px;
    /* border: 2px solid magenta; */

}
.link{
    display: flex;
    color: #a8a6a6;
    width: 100%;
    /* padding: 10px 5px; */
    /* padding: 10px 0px 0px 7px; */
    /* padding: 10; */
    gap: 15px;

    transition: all 0.5s;
    text-decoration: none;
    list-style: none;
    align-items: center;
    /* justify-content: space-between; */
    gap: 1rem;
    /* border: 2px solid red; */
}
.link.dark-theme:hover{
    /* background:#f1f1f1; */
    background: #323840;
    /* color: #000; */
    color: #afadad;
    transition: all 0.5s;
    border-radius: 7px;
}
.link.light-theme:hover{
        /* background:#f1f1f1; */
        background:whitesmoke;
        /* color: #000; */
        color: #afadad;
        transition: all 0.5s;
        border-radius: 7px;

}
.dedraw{
    /* background: #323840; */
    background-color: yellow;
    color: #afadad;
    border: 7px solid red;

}
.active{
    /* background: rgb(164, 164, 165); */
    /* background-color: #f1f1f1; */
    /* background: #323840; */
    border-radius: 7px;
    color: #afadad;
    display: flex;
    align-items: center;
    /* flex-wrap: wrap; */
}
.active.dark-theme{
    background: #323840;
}
.active.light-theme{
    background: whitesmoke;
}
.link .deleteBtn{
    display: none;
}
.active .deleteBtn{
    display: block;
}
.deactive{
    background-color: #111111;
}
.icon{
    font-size: 14px;
    list-style: none;
    text-decoration: none;
    /* border: 2px solid green; */
    width: auto;
}


.link_text{
    font-size: 14px;
    list-style: none;
    text-decoration: none;
    /* border: 2px solid green; */
    width: 100%;
    /* height: 30px; */
    /* text-emphasis: none; */
    /* text-overflow: ellipsis; */
}
.active .link_text{
    text-overflow: clip;
    display: flex;
    align-items: center;
}
.link_text_desc{
    font-size: 14px;
    text-overflow: ellipsis;
    margin: 0%;
    padding: 0%;
    display: flex;
    align-items: center;
    font-family: 'Poppins', 'Roboto', sans-serif;
    text-overflow: ellipsis;
    /* border: 2px solid red; */
    width: 100%;
}
.icon_btn_appbar{
    /* border: 2px solid yellow; */
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0rem 1rem 0rem 1rem;
}


.Right_draw_side{
    width: 100%;
    /* border: 2px solid yellow; */
    /* color: #a8a6a6; */

}
.Right_draw_side>ul{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 0.5rem; */
    padding-left: 1rem;
    text-decoration: none;
}
.Right_draw_side>ul>li{
    color: #a8a6a6;
    text-decoration: none;
    list-style: none;
    width: 100%;
    /* border: 2px solid yellowgreen; */
    list-style: none;
    margin: 0%;
    /* height: 45px; */
    /* display: flex; */
    /* align-items: center; */
    padding: 0%;

}
.Right_draw_side>ul>li>span,
.download_pdf{
    color: #a8a6a6;
    text-decoration: none;
    list-style: none;
    display: flex;
    gap: 0.5rem;
    cursor: pointer;
    align-items: center;
    border-bottom: 1px solid rgb(53, 52, 52);
    padding: 0.3rem 0rem 0.3rem 0rem;
    width: 100%;
    height: 55px;
    /* border: 2px solid yellow; */
}

.selection_bar{
    /* border: 2px solid yellowgreen; */
    color: #a8a6a6;
    text-decoration: none;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    cursor: pointer;
    align-items: flex-start;
    border-bottom: 1px solid rgb(53, 52, 52);
    padding: 0.3rem 0rem .9rem 0rem;
    width: auto;
    height: auto;
}
.selection_bar span{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.input-new-chat, .input-new-chat:focus {
    background-color: #272c33;
    color: #747d8a;
}

.closebtn-new-chat{
    background-color: white;
    color: black;
    margin-left: 10px;
}

.header-modal{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid #e9ecef;
    border-color: rgba(120, 130, 140, 0.13);
}

.footer-modal{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 15px;
    border-top: none;
}

.new-chat-close-btn:hover {
    cursor: pointer;
}

.avatar-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.avatar-title {
    color: white;
    margin-right: 10px;
}

.header-drawer{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    gap: 10px;
    width: 14%;
}

.download_flex{
    color: rgb(176, 170, 159);
    list-style-image: initial;
    padding: 10px;
    /* border-bottom: 1px solid rgb(123, 114, 101); */
}

.download_flex:hover{
    cursor: pointer;
    text-decoration: underline;
}

.icon-download{
    color: rgb(109,165,54);
    font-size: 20px;
    margin-right: 5px;
}