.heading{
    width: 80%;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: space-around;
}
.heading #menu{
    width: 3%;
    position: absolute;
    top: 10px;
    right: 0;
}

.heading h4{
    text-align: center;
}

.heading img:hover{
    cursor: pointer;
}

.heading #left-menu{
    width: 3%;
    position: absolute;
    top: 13px;
    left: 0;
}

.parent-menu.dark-theme{
    position: relative;
    /* border: 2px solid black; */
    display: flex;
    flex-direction: column;
    border-radius: 7px;
    background: transparent;
    align-items: center;
    width: 100%;
    /* border: 2px solid yellow; */
}
.parent-menu.light-theme{
    position: relative;
    /* border: 2px solid black; */
    /* background: #111111; */
    display: flex;
    flex-direction: column;
    border-radius: 7px;
    align-items: center;
    width: 100%;
    /* border: 2px solid yellow; */
}

.top-second{
    position: relative;
    /* background-color: #232223; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1050px;
    width: 85%;
    /* border: 2px solid #71ac37; */
    /* justify-content: flex-end; */
    border-radius: 10px;
    text-align: center;
	height:70vh;
	max-height: 75vh;
    /* border: 3px solid yellow; */
    margin-bottom: 2%;
    /* box-shadow: 15px #780c5f; */
    /* box-shadow: 1px 2px 15px 3px rgb(207, 210, 210); */
	overflow-y: scroll;
	overflow-x: hidden;
    padding: 1rem;
	/* padding-bottom: 35px !important; */
    scrollbar-width: thin;
    /* scrollbar-color: transparent transparent; */
}

.top-second::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
}

.top-second::-webkit-scrollbar-track {
    background-color: transparent;
}

.top-second::-webkit-scrollbar-thumb {
    background-color: transparent;
}
.context_descripation{
    /* border: 2px solid rgb(95, 20, 165); */
    /* color: white; */
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: flex-start;
    padding: 0rem 0rem 0rem 1rem;
    /* margin-bottom: -10rem; */
}
.context_heading{
    /* color: white; */
    color: #747d8a;
    font-weight: bolder;
    font-family: 'Poppins',sans-serif;
}
.context_descripation_para{
    text-align: left;
    color: #747d8a;
    font-family: 'Poppins',sans-serif;

}
.inner-bot{
    position: fixed;
    width: 10%;
    bottom: 130px;
    left: 148px;
    opacity: 0.3;
}

.bottom-div{
    width: 85%;
    /* margin: -10px auto; */
    /* border: 2px solid yellowgreen; */
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    /* width: 80%; */
    /* z-index: 999; */
}
.qns_type_field{
    width: 87%;
    /* border: 2px solid red; */
}

.f-control.dark-theme{
    /* position: absolute; */
    /* bottom: -12%; */
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 10px);
    padding: 0 20px 0 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #8b8f92;
    /* background-color:#232223; */
    /* background-color: #333033; */
    background: #323840;
    background-clip: padding-box;
    border: none;
    /* border: 1px solid #71ac37; */
    border-radius: 22px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.f-control.light-theme{
    /* position: absolute; */
    /* bottom: -12%; */
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 10px);
    padding: 0 20px 0 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    /* background-color:#232223; */
    /* background-color: #333033; */
    background: whitesmoke;
    background-clip: padding-box;
    border: none;
    /* border: 1px solid #71ac37; */
    border-radius: 22px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.f-control::placeholder{
    color: #747d8a;
}

.f-control:focus{
    /* border: 1px solid #71ac37!important; */
    outline: none;
}

.mic{
    /* position: absolute; */
    /* bottom: -10%; */
    /* right: 11%; */
    /* border: 2px solid yellow; */
    /* background-color: transparent; */
    color: white;
    /* background-color: #343a40!important; */
    /* background-color: #333033; */
    /* background: #323840; */
    /* background: rgb(39, 110, 242); */
    border-radius: 50%;
    width: 45px;
    font-size: 25px;
    font-weight: bold;
    height: 45px;
    border: none;
}

.listening-text {
    position: absolute;
    bottom: -9.5%;
    right: 18%;
    color: #71ac37;
    font-size: 18px;
}

.glowing {
    animation: glowingEffect 1s ease-in-out infinite;
}

@keyframes glowingEffect {
    0% {
        box-shadow: 0 0 5px #ff00de, 0 0 20px #ff00de, 0 0 30px #ff00de, 0 0 40px #ff00de, 0 0 70px #ff00de, 0 0 80px #ff00de, 0 0 100px #ff00de, 0 0 150px #ff00de;
    }
    50% {
        box-shadow: none;
    }
    100% {
        box-shadow: 0 0 5px #ff00de, 0 0 20px #ff00de, 0 0 30px #ff00de, 0 0 40px #ff00de, 0 0 70px #ff00de, 0 0 80px #ff00de, 0 0 100px #ff00de, 0 0 150px #ff00de;
    }
}
.send.light-theme{
        /* position: absolute; */
    /* bottom: -10%; */
    /* right: 13%; */
    /* color: white; */
    color: rgb(29, 11, 51);
    /* background-color: transparent; */
    /* background-color: #333033; */
    background-color: rgb(229, 229, 240);
    /* background: #323840; */
    /* border: 7px solid yellow;    */
    /* background-color: #343a40!important; */
    border: none;
    font-size: 25px;
    font-weight: bold;
    border-radius: 50%;
    width: 45px;
    height: 45px;

}

.send.dark-theme{
    /* position: absolute; */
    /* bottom: -10%; */
    /* right: 13%; */
    color: white;
    /* background-color: transparent; */
    /* background-color: #333033; */
    background: #323840;
    border: 7px solid yellow;   
    /* background-color: #343a40!important; */
    border: none;
    font-size: 25px;
    font-weight: bold;
    border-radius: 50%;
    width: 45px;
    height: 45px;
}

.send,.mic:hover{
    cursor: pointer;
}

.chat__body{
    padding: 24px;
    /* border: 2px solid yellow; */
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    height: 100%;
    width: 100%;
    /* overflow-x: scroll; */
}

.chat__name {
    font-size: 14px;
    color: white;
    /* border: 2px solid rgb(32, 218, 131); */
}

.user-img{
    width: 30px;
    height: 30px;
    object-fit: contain;
    color: #818181;
}

.bot-img{
    width: 16%;
    object-fit: contain;
    position: relative;
    top: -8px;
}

.chat__message {
    /* display: grid; */
    /* grid-template-columns: auto 1fr 150px; */
    /* grid-gap: 10px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    width: 100%;
    /* align-items: center; */
    /* justify-content: flex-start; */
    position: relative;
    /* border: 2px solid rgb(47, 0, 255); */
    /* overflow-x: scroll; */
}

/* .chat__message .MuiFormControl-root{
    color: red;
    min-width: 120px;
    border: 3px solid rgb(24, 120, 158);
    border-radius: 7px;
    width: 100%;
} */

.MuiFormLabel-root-MuiInputLabel-root{
    color: yellow;
}

.chat__content{
    text-align: left;
    font-family: 'Poppins', 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 16px;
    font-weight: 800;
    padding: 0px;
    width: 90%;
    border-radius: 10px;
    /* background-color: #434243; */
    /* border: 3px solid rgb(68, 5, 5); */
    color: white;
    position: relative;
}



.chat__content::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -7px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 8px 5px 0;
    border-color: transparent #111111 transparent transparent;
}
.msg_price_div{
    /* margin-top: 1rem; */
    /* margin-bottom: 1rem; */
    height: 3rem;
}

.chat__content.selected {
    background-color: #213363; 
}
.selecteds{
    background-color: #213363;
    border-radius: 10px;
    padding: 1rem;
}
.selecteds.light-theme{
    background-color: #213363;
    border-radius: 10px;
    padding: 1rem;
    color: white;

}
.no_contett_selected.dark-theme{
    /* border: 2px solid red; */
    width: 100%;
    padding: 1rem;
    background-color: #111111;
    line-height: 25px;
    font-size: 15px;
    border-radius: 10px;
}

.no_contett_selected.light-theme{
    /* border: 2px solid red; */
    width: 100%;
    padding: 1rem;
    background-color: whitesmoke;
    line-height: 25px;
    font-size: 15px;
    border-radius: 10px;
}



.chat__dark{
    text-align: left;
    font-family: 'Poppins', 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    padding: 10px;
    width: 90%;
    /* max-width: 700px; */
    /* border: 2px solid yellowgreen; */
    border-radius: 10px;
    /* background-color: #2E2D2E; */
    color: white;
    position: relative;
}

.chat__dark::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 2px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 8px 5px 0;
    /* border-color: transparent #2E2D2E transparent transparent;  */
    border-color: transparent #111111 transparent transparent; 
}

.chat__dark.selected {
    background-color: #213363; 
}

.gridInitial{
    /* display: grid; */
    width: 100%;
    /* grid-template-columns: repeatd(auto-fit,minmax(250px,1fr)); */

    display: flex;
    flex-direction: column;
    align-items: flex-start;


    
    gap: 0.75rem;
    align-content: flex-end;
    /* border: 2px solid yellowgreen; */
    height: auto;
}

.flexInitial{
    display: flex;
    flex-direction: row;
    gap: 2.5rem;
    /* border: 2px solid yellow; */
}

.initial__content{
    text-align: left;
    font-family: 'Poppins', 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    padding: 20px;
    height: 100%;
    text-align: justify;
    border-radius: 10px;
    width: auto;
    /* background-color: #434243; */
    /* background-color: #333033; */
    background: #323840;
    /* border: 2px solid rgb(33, 163, 202); */
    color: white;
    position: relative;
    cursor: pointer;
}

.initial__content.light-theme{
    background: #f9f3f3;
    color: #323840;
}

.initial__content > .initial-text{
    text-align: left;
    display: inline-block;
    width: 100%;
    color: #747d8a;
    /* border: 2px solid red; */
    text-align: center;
}

.send-icon {
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
    font-size: 20px;
    cursor: pointer;
    display: none;
}

.initial__content:hover{
    background-color: rgb(167, 164, 164);
    /* color: #e4e4e7; */
}
.initial__content > .initial-text:hover{
    /* color: #e4e4e7; */
    color: #495057;
}

.initial__content:hover .send-icon {
    display: inline-block;
}

.chat__pro.dark-theme,
.chat__pro.light-theme
{
    font-size: 14px;
    font-weight: 400;
    color: #989a9c;
    background: transparent;
}


.chat__pro::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
    height: 5px;
}

  /* Track (background of the scrollbar) */
.chat__pro::-webkit-scrollbar-track {
    /* background-color: #f1f1f1; Color of the track */
    background: transparent;
    border-radius: 6px; /* Rounded corners for the track */
}

  /* Handle (thumb of the scrollbar) */
.chat__pro::-webkit-scrollbar-thumb {
    /* background-color: #888; Color of the handle */
    background-color: #555;
    border-radius: 6px; /* Rounded corners for the handle */
}

  /* On hover, style the handle */
.chat__pro::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color of the handle on hover */
}

  /* When dragging the handle, style it */
.chat__pro::-webkit-scrollbar-thumb:active {
    background-color: #666; /* Color of the handle when active (being dragged) */
}

.loader{
    position: fixed;
    top: 35%;
    left: 45%;
    z-index: 9999;
    /* width: 100px; */
    /* border: 2px solid yellow; */
}

/* Menu Styles */
.menu-div {
    background-color: #818181;
    width: 18%;
    max-height: 400px;
	overflow-y: scroll;
	overflow-x: hidden;
	margin-bottom: 25px;

    border-radius: 10px;
    padding: 0 0 0 10px;
    position: absolute;
    right: 10%;
    top: 0;
    z-index: 9;
}
#menu-div::-webkit-scrollbar
{
	width: 5px;
	/*background-color: #8080801a;*/
}

#menu-div::-webkit-scrollbar-thumb
{
	background-color: #8080804f;
	/*border: 2px solid #555555;*/
	display:none;
}

#menu-div:hover::-webkit-scrollbar-thumb
{
	background-color: #434243;
	/*border: 2px solid #555555;*/
	display:block;
}

.menu-div ul {
    padding: 0;
    text-align: left;
}

.menu-div ul li {
    list-style-type: none;
    margin: 0;
    padding: 10px 0;
}

.menu-div ul li span {
    color: black;
}

.menu-div ul li a{
    text-decoration: none;
}

.menu-div .menu-text {
    color: black;
    font-size: 14px;
    opacity: 1;
    margin-left: 2px;
}

.menu-div .menu-text:hover {
    cursor: pointer;
}

.menu-div ul li:hover {
    background-color: #bebbbb;
    width: 94%;
    cursor: pointer;
}

.menu-div ul li small {
    color: red !important;
    opacity: 0.7;
}

.toggle-menu-button {
    margin-top: 20px;
}

.left-menu-div{
    background-color: #818181;
    width: 25%;
    border-radius: 10px;
    padding: 0 0 0 10px;
    position: absolute;
    left: 10%;
    top: 0;
    z-index: 9;
}

.msg-price {
    padding: 0;
    position: absolute;
    /* top: -20px; */
    /* border: 2px solid yellow; */
    width: 70%;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: flex-end;
    right: 24px;
    /* margin-bottom: 3rem; */
}
.msg-price.light-theme{
    background: transparent;
    /* color: #495057; */
}
.msg-price.dark-theme{
    background: transparent;
}

.msg-price>p{
    margin: 0%;
    padding: 0%;
}

.msg-price span {
    font-size: 15px;
    font-weight: 600;
    /* border: 2px solid green; */
    /* color: #434243; */
}
.avtar_send_div{
    display: flex;
    width: 100%;
    align-items: center;
    gap: 0.1rem;
}
.avtar_send_div>button{
    background-color: #495057;
    border: none;
    /* border: 1px solid rgb(163, 26, 26); */
    width: 50px;
    height: 50px;
    border-radius: 50px;
}
.avtar_send_div>button:hover{
    background-color: #747d8a;
    color: #0e0d0d;
}

.flex__similarity{
    display: flex;
    justify-content: space-between;
}

.flex__similarity p{
    color: #afadad;
    margin: 5px 0;
    font-size: 14px;
}

@media (max-width:1080px) {
    .flexInitial{
        display: flex;
        flex-wrap: wrap;
        row-gap: 1.5rem;
    }
    .initial__content{
        height: auto;
    }
    
}

/* Styles for mobile devices */
@media (max-width: 767px) {
    .top-second{
        width: 100%;
        padding: 0rem;
    }
    .chat__body{
        width: 100%;
        padding: 0%;
    }
    .chat__dark{
        padding: 0%;
        width: 90%;
        position: unset;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.25);
        padding: 0.5rem;
        /* border: 2px solid red; */
        /* background: #666; */
    }
    
    
    .msg_price_div{
        width: 90%;
        margin-bottom: 1rem;
        margin-top: 0rem;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .msg-price{
        top: 0px;
        width: 100%;
        margin-bottom: 0rem;
        padding: 0%;
        right: 12%;
        position: unset;
    }
    .context_descripation{
        width: 100%;
        padding: 1%;
    }
    .no_contett_selected.dark-theme,
    .no_contett_selected.light-theme{
        padding: 0.5rem;
        width: 100%;
        text-align: justify;
    }
    
    .flexInitial{
        flex-direction: column;
    }
    .bottom-div{
        width: 100%;
        margin: 1rem 0rem 0rem 0rem;
    }
    .send {
        right: 16%;
    }
    .bot-img {
        width: 32%;
    }
    .heading #menu{
        width: 8%;
        top: -2px;
    }
    .heading #left-menu{
        width: 8%;
        top: 1px;
    }
    .menu-div{
        width: 60%;
        right: 17px;
    }
    .left-menu-div{
        width: 60%
    }
    .inner-bot{
        display: none;
    }
    .chat__name{
        justify-self: flex-start;
        /* border: 3px solid yellow; */
    }
    .chat__message {
        grid-template-columns: none;
        align-items: flex-start;
        /* border: 2px solid yellow; */
    }
    .MuiAutocomplete-root{
        width: 100px;

    }
    .chat__message .MuiFormControl-root{
        width: 100%;
    }
    /* .chat__content::before, .chat__dark::before {
        display: none;
    } */
    .selecteds{
        width: inherit;
    }

    .chat__dark::before{
        top: 20px;
        left: 7%;
        /* position: relative; */
        /* position: unset; */
        /* left: auto; */
    }
    .flex__similarity{
        flex-direction: column;
        width: 100%;
    }
    .send.dark-theme,
    .send.light-theme{
        width: 45px;
        height: 40px;
        font-size: 15px;
    }

}
